<template>
    <div id="privacy">
        <h1>Privacy Policy</h1>
        <p>
            Some data may be collected incidentally as a byproduct of logging infrastructure. This includes information
            about the requests sent such as: ip addresses, request paths and parameters, and certain http headers, like
            referer.
        </p>
        <p>
            Rating is anonymous. We don't know who you are unless you've told us who you are, but it isn't recorded with
            the ratings anyway.
        </p>
        <p>
            If you choose to upload, we'll collect your email address, to help cut down on the nonsense. We'll store
            that along with any text or images you send us.
        </p>
        <p>
            We reserve the right to change this privacy policy. Any revisions will be posted at least 30 days prior to
            its effective date.
        </p>
        <p>This should be fun, that's what it's for.</p>
    </div>
</template>
<style lang="scss">
#privacy {
    padding: 40px;
}
</style>
