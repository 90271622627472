<template>
    <div id="account">
        <div class="header">
            <h1>My Houses</h1>
            <div class="actions">
                <router-link to="/upload" class="button secondary">Upload More!</router-link>
            </div>
        </div>

        <HouseList :houses="approved" title="Approved" />
        <HouseList :houses="pending" title="Pending" />
        <HouseList :houses="rejected" title="Rejected" />

        <LoginModal v-if="!loggedIn" />
    </div>
</template>
<script>
import HouseList from '@/components/HouseList.vue'
import LoginModal from '@/components/LoginModal.vue'
import { mine } from '@/houses.js'

export default {
    components: {
        LoginModal,
        HouseList,
    },
    data() {
        return {
            $async: {
                mine: null,
            },
            mine: [],
        }
    },
    watch: {
        asyncMine: {
            async handler() {
                const promise = this.asyncMine
                this.$async = promise
                const mine = await promise
                if (promise === this.asyncMine) {
                    this.mine = mine
                }
            },
            immediate: true,
        },
    },
    computed: {
        approved() {
            return this.mine.filter((h) => h.status === 'Approved')
        },
        asyncMine: {
            get() {
                return mine()
            },
        },
        pending() {
            return this.mine.filter((h) => h.status === 'Pending')
        },
        rejected() {
            return this.mine.filter((h) => h.status === 'Rejected')
        },
        loggedIn() {
            return this.$jwt.id > 0
        },
    },
}
</script>
<style lang="scss">
#account {
    padding: 40px;
}
</style>
