<template>
    <Modal>
        <div ref="button"></div>
    </Modal>
</template>
<script>
import { login } from '@/auth.js'
import Modal from './Modal.vue'

export default {
    mounted() {
        window.google.accounts.id.initialize({
            client_id: '642944800945-23n7b0s91hvlaakv6l0e11polb3jvsth.apps.googleusercontent.com',
            callback: (payload) => this.login(payload),
        })

        window.google.accounts.id.renderButton(this.$refs.button, { theme: 'outline', size: 'large' })
    },
    methods: {
        login(payload) {
            login(payload.credential)
        },
    },
    components: { Modal },
}
</script>
