<template>
    <div class="modal-backdrop">
        <div class="modal-content">
            <slot />
        </div>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    background-color: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;

    .modal-content {
        background: #ffffff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
        overflow: auto;
        display: flex;
        flex-direction: column;
        margin: auto;
        max-height: 100vh;
        max-width: 100vw;
    }
}
</style>
