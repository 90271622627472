<template>
    <div id="upload">
        <div :class="{ preview: true, previewing }">
            <DropTarget v-if="!image && !previewing" />
            <img :src="image" v-if="image" style="{ width, height }" />
        </div>

        <div class="button-group" v-if="!previewing">
            <button @click="capturing = true" class="primary">Take Picture</button>
            <button @click="image = null" class="secondary" :disabled="!image">Discard</button>
        </div>
        <div class="button-group" v-else>
            <button @click="takeSnapshot" class="primary">Cheese!</button>
            <button @click="cancelSnapshot" class="secondary">Cancel</button>
        </div>

        <label>
            <span>Description</span>
            <input type="text" v-model="description" />
        </label>

        <button class="primary large" @click="upload" :disabled="!image">Upload</button>

        <table style="width: 100vw">
            <tr>
                <td colspan="2">
                    <div v-for="(error, index) in errors" :key="index" style="width: 100vw; overflow: hidden">
                        {{ error }}
                    </div>
                </td>
            </tr>
        </table>

        <LoginModal v-if="!loggedIn" />
        <CaptureModal v-if="capturing" @captured="captured" @dismiss="capturing = false" />
    </div>
</template>
<script>
import CaptureModal from '@/components/CaptureModal.vue'
import DropTarget from '@/components/DropTarget.vue'
import LoginModal from '@/components/LoginModal.vue'
import { upload } from '@/houses'

export default {
    components: {
        LoginModal,
        CaptureModal,
        DropTarget,
    },
    data() {
        return {
            capturing: false,
            description: '',
            errors: [],
            image: null,
        }
    },
    computed: {
        loggedIn() {
            return this.$jwt.id > 0
        },
    },
    methods: {
        captured(image) {
            this.image = image
            this.capturing = false
        },
        async upload() {
            const house = await upload(this.description, this.image)
            if (house !== null) {
                await this.$router.replace({ name: 'account' })
            }
        },
    },
}
</script>
<style lang="scss">
#upload {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 16px;
    gap: 24px;

    label,
    .preview {
        max-width: calc(100vw - 32px);
        width: 640px;
    }

    .preview {
        display: flex;
        position: relative;

        max-height: 50vw;
        height: 480px;

        video,
        img {
            display: initial;
            object-fit: scale-down;
            margin: 0 auto;
            max-width: 100%;
            max-height: 100%;
            z-index: 1000;
        }

        video {
            display: none;
        }

        &.previewing {
            video {
                display: block;
            }

            img {
                display: none;
            }
        }
    }

    .button-group {
        max-width: calc(100vw - 32px);
        width: 640px;
    }
}
</style>
