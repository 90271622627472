<template>
    <Modal>
        <div class="capture-modal">
            <video ref="preview" autoplay muted playsinline :style="{ zoom }" />
            <canvas ref="canvas" :width="width" :height="height" style="display: none" />
            <i class="material-icons dismiss-icon" @click="dismiss"> close </i>
            <i class="material-icons capture-icon" @click="capture"> photo_camera </i>
        </div>
    </Modal>
</template>
<script>
import Modal from './Modal.vue'

export default {
    components: { Modal },
    data() {
        return {
            description: '',
            errors: [],
            height: 0,
            image: null,
            previewing: false,
            width: 0,
            zoom: 1,
        }
    },
    mounted() {
        this.startPreview()
    },
    unmounted() {
        this.cancelSnapshot()
    },
    computed: {
        loggedIn() {
            return this.$jwt.id > 0
        },
    },
    methods: {
        cancelSnapshot() {
            if (this._stream) {
                this._stream.getTracks().forEach((track) => track.stop())
                this._stream = null
            }
            this.previewing = false
        },
        async startPreview() {
            if (this.previewing) {
                return
            }
            this.previewing = true
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: 'environment' },
                    audio: false,
                })
                this.$refs.preview.srcObject = stream
                this._stream = stream
                this.errors.push(stream.getVideoTracks().length)
                const track = stream.getVideoTracks()[0].getSettings()
                this.errors.push(JSON.stringify(track, null, 2))
                this.width = track.width
                this.height = track.height
                this.zoom = Math.min(window.innerWidth / track.width, (window.innerHeight * 0.75) / track.height, 1)
            } catch (error) {
                this.errors.push(error)
                this.previewing = false
            }
        },
        capture() {
            const height = this.$refs.preview.offsetHeight
            const width = this.$refs.preview.offsetWidth

            const canvas = this.$refs.canvas

            canvas.height = height
            canvas.width = width

            const context = canvas.getContext('2d')

            context.drawImage(this.$refs.preview, 0, 0, width, height, 0, 0, width, height)
            this.cancelSnapshot()

            this.$emit('captured', canvas.toDataURL('image/jpeg', 1), {
                width: this.width,
                height: this.height,
                zoom: this.zoom,
            })
        },
        dismiss() {
            this.$emit('dismiss')
        },
    },
}
</script>
<style lang="scss">
.capture-modal {
    padding: 0;
    position: relative;

    .material-icons {
        background-color: #ffffff40;
        border: 1px solid black;
        border-radius: 20px;
        cursor: pointer;
        line-height: 24px;
        padding: 8px;
        position: absolute;
    }

    .capture-icon {
        bottom: 16px;
        left: calc(50% - 20px);
    }

    .dismiss-icon {
        top: 16px;
        right: 16px;
    }
}
</style>
