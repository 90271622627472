<template>
    <div id="home">
        <div class="goat">
            <RatedHouse :house="house" v-for="house in goat" :key="`goat-${house.id}`" />
        </div>
        <div class="rate">
            <div class="rating-box" v-if="ballot">
                <HouseImage :house="ballot.house" />

                <div>
                    {{ ballot.house.description }}
                </div>

                <RatingBar
                    @hover="(value) => (hover = value)"
                    @input="(value) => (pending = value)"
                    :rating="pending || hover"
                />

                <button class="primary large" :disabled="disableVote" @click="rate">Rate</button>
            </div>

            <router-link to="/upload" class="button secondary large">Upload Mine!</router-link>
        </div>
        <div class="hot">
            <RatedHouse :house="house" v-for="house in hot" :key="`hot-${house.id}`" />
        </div>
    </div>
</template>
<script>
import { top, ballot, vote } from '@/houses.js'

import RatingBar from '@/components/RatingBar.vue'
import RatedHouse from '@/components/RatedHouse.vue'
import HouseImage from '@/components/HouseImage.vue'

export default {
    name: 'HomeView',
    components: {
        RatingBar,
        RatedHouse,
        HouseImage,
    },
    data() {
        return {
            top: {},
            ballot: null,
            pending: 0,
            hover: 0,
        }
    },
    watch: {
        asyncTop: {
            async handler() {
                this.top = await this.asyncTop
            },
            immediate: true,
        },
        asyncBallot: {
            async handler() {
                this.ballot = await this.asyncBallot
            },
            immediate: true,
        },
    },
    computed: {
        asyncTop: {
            get() {
                return top()
            },
            default() {
                return []
            },
        },
        disableVote() {
            return this.pending < 1 || this.voting
        },
        goat: {
            get() {
                return this.top.goat ?? []
            },
            default() {
                return []
            },
        },
        hot: {
            get() {
                return this.top.hot ?? this.goat
            },
        },
        asyncBallot: {
            get() {
                return ballot()
            },
            default() {
                return null
            },
        },
    },
    methods: {
        async rate() {
            this.voting = true

            await vote(this.ballot.token, this.pending)

            this.pending = 0
            this.ballot = await ballot()

            this.voting = false
        },
    },
}
</script>
<style lang="scss">
#home {
    display: grid;
    overflow: visible;

    grid-template-areas: 'goat rate rate rate hot';

    > * {
        height: 100%;
    }

    > .rate {
        background-color: white;
        display: flex;
        flex-direction: column;
        grid-area: rate;
        padding: 40px;
        align-items: center;

        img {
            margin: 0 auto;
        }

        > .rating-box {
            align-items: center;
            display: flex;
            flex-direction: column;

            min-height: calc(100vh - 300px);
        }

        > .button.large {
            display: block;
            position: sticky;
            margin-top: 40px;
            width: 100%;
        }
    }

    > .goat,
    > .hot {
        background-color: green;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 40px;
        align-items: center;
        justify-content: space-evenly;

        .rated-house {
            width: 50%;
        }

        > div {
            display: flex;
            flex-direction: column;
        }

        img {
            display: block;
            margin: 0 auto;
        }
    }

    > .goat {
        grid-area: goat;
    }

    > .hot {
        grid-area: hot;
    }

    @media (max-width: 480px) {
        grid-template-areas:
            'rate'
            'goat'
            'hot';

        > .hot {
            display: none;
        }
    }
}
</style>
