<template>
    <div
        id="g_id_onload"
        data-client_id="642944800945-23n7b0s91hvlaakv6l0e11polb3jvsth.apps.googleusercontent.com"
        data-context="signin"
        data-ux_mode="popup"
        data-callback="googleLogin"
        data-auto_prompt="false"
    ></div>

    <div
        class="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left"
    ></div>
</template>
<script>
import { login } from '@/auth.js'

export default {
    mounted() {
        window.googleLogin = (payload) => this.login(payload)
    },
    methods: {
        login(payload) {
            login(payload.credential)
        },
    },
}
</script>
