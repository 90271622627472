<template>
    <img :src="src" style="max-width: 100%" />
</template>
<script>
export default {
    props: {
        house: {
            type: Object,
            required: true,
        },
        status: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        pending() {
            return (this.status ?? this.house.status ?? 'Approved') !== 'Approved'
        },
        src() {
            if (this.pending) {
                return `/api/pending/images/${this.house.id}`
            }

            const str = [...this.house.id.toString()].reverse()

            return `/content/houses/${str[0] ?? 0}/${str[1] ?? 0}/${str[2] ?? 0}/${str[3] ?? 0}/${this.house.id}`
        },
    },
}
</script>
