export async function get(uri) {
    const response = await window.fetch(uri)

    if (response.status === 200) {
        return response.json()
    }

    return null
}

export async function post(uri, payload = null) {
    const options = { method: 'POST' }

    if (payload !== null) {
        options.headers = { 'Content-Type': 'application/json' }
        options.body = JSON.stringify(payload)
    }

    const response = await window.fetch(uri, options)

    if (response.status === 200) {
        return response.json()
    }

    return null
}

export async function put(uri, payload = null) {
    const options = { method: 'PUT' }

    if (payload !== null) {
        options.headers = { 'Content-Type': 'application/json' }
        options.body = JSON.stringify(payload)
    }

    const response = await window.fetch(uri, options)

    if (response.status === 200) {
        return response.json()
    }

    return null
}

export async function del(uri) {
    const response = await window.fetch(uri, { method: 'DELETE' })

    if (response.status === 200) {
        return response.json()
    }

    return null
}
