<template>
    <div class="rated-house">
        <HouseImage :house="house" />
        <div>
            {{ house.description }}
        </div>
        <RatingBar class="small" :rating="house.rating" />
    </div>
</template>
<script>
import RatingBar from '@/components/RatingBar.vue'
import HouseImage from './HouseImage.vue'

export default {
    components: {
        RatingBar,
        HouseImage,
    },
    props: {
        house: {
            type: Object,
            required: true,
        },
    },
}
</script>
<style lang="scss">
.rated-house {
    align-items: center;
    display: flex;
    flex-direction: column;

    > img {
        max-height: 20vh;
        max-width: 100%;
    }

    .rating-bar {
        width: 50%;
    }
}
</style>
