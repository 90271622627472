<template>
    <div class="drop-target">
        <div>
            <!--
            Drop Image File Here<br />
            OR<br />
            Click to Browse
            -->
        </div>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.drop-target {
    background-color: #00800080;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > div {
        color: white;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
    }
}
</style>
