import { get, post, put, del } from '@/request.js'

export async function top() {
    return (await get('/api/top')) ?? { goat: [], hot: [] }
}

export async function mine() {
    return (await get('/api/houses/mine')) ?? []
}

export async function ballot() {
    return await post('/api/ballots')
}

export async function vote(token, rating) {
    return await put(`/api/ballots/${token}?rating=${rating}`, {})
}

export async function approvalQueue() {
    return (await get('/api/approvals')) ?? []
}

export async function approve(id) {
    return await put(`/api/approvals/${id}`)
}

export async function reject(id) {
    return await del(`/api/approvals/${id}`)
}

export async function upload(description, image) {
    const response = await window.fetch('/api/houses', {
        method: 'POST',
        body: JSON.stringify({ description, image }),
        headers: {
            'Content-Type': 'application/json',
        },
    })

    if (response.status === 200) {
        return await response.json()
    }

    return null
}
