import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import AccountView from '@/views/AccountView'
import ApproveView from '@/views/ApproveView'
import LoginView from '@/views/LoginView'
import PrivacyView from '@/views/PrivacyView'
import TermsView from '@/views/TermsView'
import UploadView from '@/views/UploadView'

const routes = [
    { path: '/', name: 'home', component: HomeView },
    { path: '/account', name: 'account', component: AccountView },
    { path: '/approve', name: 'approve', component: ApproveView },
    { path: '/login', name: 'login', component: LoginView },
    { path: '/privacy', name: 'privacy', component: PrivacyView },
    { path: '/terms', name: 'terms', component: TermsView },
    { path: '/upload', name: 'upload', component: UploadView },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
