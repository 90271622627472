<template>
    <div class="rating-bar">
        <i class="material-icons" @click="rate(1)" @mouseover="hover(1)" @mouseout="hover(0)">
            {{ rating > 0.5 ? 'star' : 'star_outline' }}
        </i>
        <i class="material-icons" @click="rate(2)" @mouseover="hover(2)" @mouseout="hover(0)">
            {{ rating > 1.5 ? 'star' : 'star_outline' }}
        </i>
        <i class="material-icons" @click="rate(3)" @mouseover="hover(3)" @mouseout="hover(0)">
            {{ rating > 2.5 ? 'star' : 'star_outline' }}
        </i>
        <i class="material-icons" @click="rate(4)" @mouseover="hover(4)" @mouseout="hover(0)">
            {{ rating > 3.5 ? 'star' : 'star_outline' }}
        </i>
        <i class="material-icons" @click="rate(5)" @mouseover="hover(5)" @mouseout="hover(0)">
            {{ rating > 4.5 ? 'star' : 'star_outline' }}
        </i>
    </div>
</template>
<script>
export default {
    props: {
        rating: {
            type: Number,
            required: true,
        },
    },
    methods: {
        rate(rating) {
            this.$emit('input', rating)
        },
        hover(rating) {
            this.$emit('hover', rating)
        },
    },
}
</script>
<style lang="scss">
.rating-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-items: center;
    margin-top: 24px;
    user-select: none;
    width: 100%;

    .material-icons {
        color: gold;
        flex-grow: 1;
        font-size: 48px;
        text-align: center;
    }

    &.small {
        .material-icons {
            font-size: 24px;
        }
    }
}
</style>
