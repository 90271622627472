<template>
    <nav>
        <router-link to="/" class="masthead">ratemygingerbreadhouse.com</router-link>
        <router-link to="/approve" v-if="admin">
            <i class="material-icons"> task_alt </i>
        </router-link>
        <router-link to="/account">
            <i class="material-icons"> person_outline </i>
        </router-link>
    </nav>
    <div class="divider small"></div>
    <div id="router-view">
        <router-view v-if="inSeason" />
        <div v-else style="padding: 40px">
            <h1>'Tis Not The Season...</h1>
            <p>...to be rating gingerbread houses. We might try it again next year.</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            inSeason: false,
        }
    },
    computed: {
        admin() {
            return this.$jwt.roles.has('admin')
        },
    },
}
</script>
<style lang="scss">
@import '~@/fonts/material-icons.scss';

* {
    box-sizing: border-box;
}

a,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}

body {
    font-family: Arial;
    padding: 0;
    margin: 0;
}

img {
    display: block;
}

button,
.button {
    border-style: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    line-height: 40px;
    font-size: 20px;
    min-width: 40px;
    padding: 0 20px;
    text-align: center;
    white-space: nowrap;

    &.primary {
        background-color: green;
        color: white;
    }

    &.secondary {
        background-color: red;
        color: white;
    }

    &.large {
        font-size: 40px;
        line-height: 80px;
        padding: 0 20px;
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 16px;
    width: 100%;
}

label {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;

    span {
        font-weight: bold;
    }

    input {
        line-height: 32px;
        border: 1px solid black;
        border-radius: 4px;
        flex-grow: 1;
        padding: 0 16px;
    }
}

.house-list {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(240px, 360px));
}

.header {
    display: flex;
    align-items: center;

    h1 {
        flex-grow: 1;
    }
}

#app {
    display: flex;
    flex-direction: column;

    > * {
        flex-shrink: 0;
    }

    > nav {
        align-items: center;
        background-color: red;
        color: white;
        display: flex;
        font-size: 5vw;
        height: 80px;
        padding: 0 40px;
        justify-content: space-between;
        width: 100%;

        .masthead {
            flex-grow: 1;
            width: 1%;
        }

        a {
            display: flex;

            .material-icons {
                font-size: 6vw;
            }
        }

        @media (min-width: 1200px) {
            font-size: 60px;

            a {
                .material-icons {
                    font-size: 72px;
                }
            }
        }
    }

    #router-view {
        height: calc(100vh - 88px);
        overflow: auto;
    }
}

.divider {
    background-image: url('@/images/candy-cane-tile.png');
    background-size: 16px 16px;
    height: 16px;

    &.small {
        background-size: 8px 8px;
        height: 8px;
    }

    &.large {
        background-size: 32px 32px;
        height: 32px;
    }
}
</style>
