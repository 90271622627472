<template>
    <div id="approval">
        <div class="house-list">
            <div v-for="house in queue" :key="house.id">
                <HouseImage :house="house" status="Pending" />
                <div>{{ house.description }}</div>
                <div class="button-group">
                    <button class="primary" @click="approve(house)">Approve</button>
                    <button class="secondary" @click="reject(house)">Reject</button>
                </div>
            </div>
        </div>

        <LoginModal v-if="!loggedIn" />
    </div>
</template>
<script>
import HouseImage from '@/components/HouseImage.vue'
import LoginModal from '@/components/LoginModal.vue'
import { approvalQueue as queue, approve, reject } from '@/houses.js'

export default {
    data() {
        return {
            queue: [],
        }
    },
    watch: {
        asyncQueue: {
            async handler() {
                this.queue = await this.asyncQueue
            },
            immediate: true,
        },
    },
    computed: {
        asyncQueue: {
            get() {
                return queue()
            },
            default() {
                return []
            },
        },
        loggedIn() {
            return this.$jwt.id > 0
        },
    },
    methods: {
        async approve(house) {
            await approve(house.id)
            await this.remove(house)
        },
        async reject(house) {
            await reject(house.id)
            await this.remove(house)
        },
        async remove(house) {
            this.queue.splice(house, 1)
            if (this.queue.length === 0) {
                this.queue = await queue()
            }
        },
    },
    components: { HouseImage, LoginModal },
}
</script>
<style lang="scss">
#approval {
    padding: 40px;

    .button-group {
        margin-top: 16px;
    }
}
</style>
