<template>
    <h2>
        {{ title }}
    </h2>

    <div class="house-list">
        <RatedHouse v-for="(house, index) in houses" :key="index" :house="house" />
    </div>
</template>
<script>
import RatedHouse from './RatedHouse.vue'

export default {
    components: {
        RatedHouse,
    },
    props: {
        houses: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
}
</script>
<style lang="scss"></style>
