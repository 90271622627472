<template>
    <h1>Terms of Service</h1>
    <p>This is what you agree to when you're using this site. If you don't agree, don't use this site.</p>
    <p>
        This is a silly little website for rating gingerbread houses. The expectation is that you upload a picture of a
        gingerbread house and people rate it. That's it, really. Don't upload things that aren't gingerbread houses.
        Don't upload anything obscene. Use it for its intended purpose or don't use it at all.
    </p>
</template>
<style lang="scss">
#terms {
    padding: 40px;
}
</style>
