const { reactive } = require('vue')

import { post } from '@/request.js'

const jwt = reactive({
    id: null,
    email: null,
    roles: new Set(),
})

function parse() {
    const match = /(^|;)\s*rmgbh=(?<value>[^;]+)(;|$)/.exec(document.cookie)

    if (!match) {
        return
    }

    const parsed = JSON.parse(atob(match.groups.value))

    jwt.id = parsed.id ?? null
    jwt.email = parsed.sub ?? null
    jwt.roles = new Set(parsed.role ?? [])
}

export async function login(credential) {
    const token = await post('/api/login', credential)

    if (token) {
        jwt.id = token.id ?? null
        jwt.email = token.sub ?? null
        jwt.roles = new Set(token.role ?? [])
    }

    return token
}

export default {
    install: (app) => {
        parse()
        app.config.globalProperties.$jwt = jwt
    },
}
